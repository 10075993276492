import { render, staticRenderFns } from "./RegisterPro.vue?vue&type=template&id=845b28b0"
import script from "./RegisterPro.vue?vue&type=script&lang=js"
export * from "./RegisterPro.vue?vue&type=script&lang=js"
import style0 from "./RegisterPro.vue?vue&type=style&index=0&id=845b28b0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports