<template>
<!-- Is this still in use? -->
  <div class="bg-guestio-gray-100 py-16">
    <div class="max-w-lg bg-white w-full mx-auto shadow-xl border rounded-xl px-12 py-10">
      <div class="flex justify-between mb-10">
        <h4 class="text-4xl font-bold flex items-center">
          <span class="mr-4">{{ mainTitle }}</span>
        </h4>
      </div>
      <ValidationObserver ref="userForm" v-slot="{ handleSubmit, valid }" tag="div">
        <form @submit.prevent="handleSubmit(submmitRegistration)" method="post" class="grid grid-cols-1 row-gap-6">
          <div>
            <label class="register-label">First name</label>
            <ValidationProvider rules="required" vid="first_name" name="First name" v-slot="{ errors }">
              <TextInput v-model="userForm.first_name" :errors="errors" id="first_name" padding-y="py-3"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Last name</label>
            <ValidationProvider rules="required" vid="last_name" name="Last name" v-slot="{ errors }">
              <TextInput v-model="userForm.last_name" :errors="errors" id="last_name" padding-y="py-3"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Email</label>
            <ValidationProvider rules="required" vid="email" name="Email" v-slot="{ errors }">
              <TextInput v-model="userForm.email" :errors="errors" :type="'email'" id="email" padding-y="py-3"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Phone</label>
            <ValidationProvider rules="required" vid="phone" name="Phone" v-slot="{ errors }">
              <div>
                <the-mask type="tel" v-model="userForm.phone" :mask="'(###) ###-####'" class="guestio-form-input" />
              </div>
              <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Password</label>
            <ValidationProvider rules="required" vid="password" name="Password" v-slot="{ errors }">
              <TextInput v-model="userForm.password" :errors="errors" :type="'password'" id="password" padding-y="py-3"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Confirm password</label>
            <ValidationProvider rules="required" vid="password_confirmation" name="Password confirmation" v-slot="{ errors }">
              <TextInput v-model="userForm.password_confirmation" :errors="errors" :type="'password'" id="password_confirmation" padding-y="py-3"/>
            </ValidationProvider>
          </div>

          <div>
            <label class="register-label" for="plan">Plan</label>
            <ValidationProvider rules="required" vid="plan" name="Plan" v-slot="{ errors }" tag="div">
              <select class="form-select w-full px-5 py-3 rounded-full bg-gray-50 border-transparent focus:shadow-3xl text-sm" id="plan" v-model="selectedPlan">
                <option v-for="plan in availablePlans" :value="plan.key" :key="`price-${plan.key}`">
                  {{ plan.label }} - ${{ plan.price }}/{{ plan.key == 'monthly' ? 'month' : 'year' }}
                </option>
              </select>
              <p class="text-gray-500 text-sm mt-2 font-light" v-if="! errors.length">
                Includes a 14 day trial before billing begins
              </p>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <CreditCard @saveCard="setSaveCard" @cardComplete="setCardComplete"/>

          <div v-if="paymentSetupError" class="mt-6">
            <p class="text-red-500">There was an error while attempting to start your subscription.</p>
            <p v-if="errorDetails" class="mt-2 text-red-500">{{ errorDetails }}</p>
          </div>

          <div class="flex flex-col items-center justify-center mt-6">
            <button type="submit" class="rounded-full px-10 py-3 bg-indigo-gradiant text-white disabled:opacity-50" :disabled="! formReady(valid) || loading">
              <span v-if="loading">
                <loading-icon class="h-5 w-5"/>
              </span>
              <span v-else>Save & Continue</span>
            </button>
          </div>
        </form>
      </ValidationObserver>

      <div class="mt-8 flex justify-center">
        <SecureCheckoutSvg class="h-10" />
      </div>

      <modal ref="termsModal" class="hidden">
        Terms and conditions

        <template #content>
          <div class="w-full max-h-screen py-4 md:py-10 md:px-8 lg:py-12 lg:px-12 flex flex-col items-center justify-center">
            <div class="bg-white flex-1 md:mx-8 lg:mx-12 lg:max-w-6xl w-full rounded-3xl flex flex-col overflow-hidden">
              <div class="flex-shrink-0 pt-10">
                <h2 class="text-xl font-bold text-center">Terms and Conditions</h2>
              </div>
              <div class="terms mt-8 flex-1 ml-6 mr-3 md:mx-16 lg:mx-24 pr-3 md:pr-6 lg:pr-8 overflow-y-scroll">
                <TermsAndConditions />
              </div>
              <div class="my-6 flex flex-col md:flex-row items-center justify-center md:space-x-16">
                <button @click.prevent="declineTerms" type="button" class="order-2 md:order-1 uppercase text-pink-500 font-semibold mt-4 md:mt-0">Decline</button>
                <button @click.prevent="acceptTerms" type="button" class="btn order-1 md:order-2">Accept</button>
              </div>
            </div>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import { mapState } from "vuex";
  import { TheMask } from 'vue-the-mask'
  import { find } from 'lodash'
  import TextInput from "@/components/shared/TextInput"
  import timezones from '@/mixins/timezones'
  import Modal from '@/components/shared/Modal'
  import TermsAndConditions from '@/components/register/TermsAndConditions'
  import { confirmCardSetup } from 'vue-stripe-elements-plus'
  import SecureCheckoutSvg from '@/components/svgs/SecureCheckout'
  import CreditCard from '@/components/payment-methods/CreditCard';

  export default {
    name: "RegisterPro",

    components: {
      TextInput,
      TheMask,
      Modal,
      TermsAndConditions,
      SecureCheckoutSvg,
      CreditCard,
    },

    mixins: [timezones],

    data() {
      return {
        loading: false,
        showTermsModal: false,
        cardComplete: false,
        card: null,
        paymentSetupError: false,
        errorDetails: null,
        paymentMethod: null,
        saveCard: false,
        selectedPaymentMethod: null,
        intent: null,
      };
    },

    computed: {
      ...mapState('register', {
        steps: state => state.steps,
        userForm: state => state.userForm,
      }),

      user() {
        return this.$store.getters['auth/getUser']
      },

      mainTitle() {
        let step = find(this.steps, ['stepNumber', this.currentStep]);
        return step.name;
      },

      currentStep: {
        get() {
          return this.$store.getters['register/currentStep']
        },
        set(newValue) {
          return this.$store.dispatch('register/setCurrentStep', newValue)
        }
      },

      selectedPlan: {
        get() {
          return this.$store.getters['register/selectedPlan']
        },
        set(plan) {
          this.$store.commit('register/setSelectedPlan', plan)
        }
      },

      availablePlans() {
        let plans = this.$store.getters['register/availablePlans']

        return plans.filter(plan => plan.price > 0)
      },

      activePlan() {
        if (! this.selectedPlan) {
          return {}
        }

        return this.availablePlans.find(plan => plan.key == this.selectedPlan)
      },

      price() {
        if(this.plan == 'yearly') {
          return process.env.VUE_APP_PRO_YEARLY_PRICE ? process.env.VUE_APP_PRO_YEARLY_PRICE : 997;
        }

        return process.env.VUE_APP_PRO_MONTHLY_PRICE ? process.env.VUE_APP_PRO_MONTHLY_PRICE : 97;
      }
    },

    methods: {
      formReady(valid) {
        return this.cardComplete && valid
      },

      async submmitRegistration() {
        if (! this.userForm.terms_accepted) {
          this.openTermsModal()
          return
        }

        this.loading = true

        try {
          if (! this.user.id) {
            await this.registerUser()
          }

          await this.validatePaymentMethod()

          await this.createSubscription()

          this.next()
        } catch (error) {
          console.log(error)
          this.loading = false

          if (error) {
            this.$toast.error(error)
          }
        }
      },

      registerUser() {
        return this.$store
          .dispatch('auth/register', this.userForm)
          .catch(error => {
            if (error.response) {
              this.$refs.userForm.setErrors(error.response.data.errors)
              this.$toast.error('Correct the form errors.')
              throw null
            } else {
              throw 'Error! Please correct the form errors.'
            }
          })
      },

      async validatePaymentMethod() {
        if (! this.cardComplete) {
          throw 'Fill in all payment details.'
        }

        this.loading = true
        this.paymentSetupError = false
        this.errorDetails = null

        const { setupIntent, error } = await confirmCardSetup(this.intent.client_secret, {
          payment_method: {
            card: this.card
          }
        })

        if (error) {
          this.loading = false
          this.paymentSetupError = true

          if (error.message) {
            this.errorDetails = error.message
          }

          throw error.message
        }

        this.selectedPaymentMethod = setupIntent.payment_method

        if (this.saveCard) {
          await this.$store.dispatch('auth/storePaymentMethod', {
            payment_method: this.selectedPaymentMethod,
            save: this.saveCard,
          }).catch(error => {
            if (error.response) {
              this.errorDetails = error.response.data.message
              throw error.response.data.message
            }
          })
        }
      },

      createSubscription() {
        return api.post('/users/me/subscription', {
          plan: this.activePlan.key,
          payment_method: this.selectedPaymentMethod,
          price: this.price,
        })
        .catch(error => {
          this.generatePaymentIntent()
          this.loading = false
          this.paymentSetupError = true

          if (error.response) {
            this.errorDetails = error.response.data.message
          }

          throw this.errorDetails || 'Subscription not created. Unknown error.'
        })
      },

      next() {
        this.$router.push({name: 'register-profile-type'})
      },

      openTermsModal() {
        this.$refs.termsModal.open()
      },

      acceptTerms() {
        this.userForm.terms_accepted = true
        this.$refs.termsModal.close()
        this.submmitRegistration()
      },

      declineTerms() {
        this.userForm.terms_accepted = false
        this.$refs.termsModal.close()
      },

      guessTimezone() {
        let zone = this.$moment().utcOffset()

        if (this.$moment().isDST()) {
          zone -= 60
        }

        let timezone = this.timezones.find(timezone => timezone.timezone == Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (! timezone) {
          timezone = this.timezones.find(timezone => timezone.offset == (zone / 60));
        }

        if (timezone) {
          this.userForm.timezone = timezone.timezone
        }
      },

      generatePaymentIntent() {
        api.get(`/payment-intent?usage=off_session`).then(response => {
          this.intent = response.data.intent
        })
      },

      setCardComplete(data) {
        this.cardComplete = data.complete;
        this.card = data.card;
      },

      setSaveCard(shouldSave) {
        this.saveCard = shouldSave;
      },
    },

    mounted() {
      this.generatePaymentIntent()
      this.guessTimezone()

      if (this.$route.query.plan && ['monthly', 'yearly'].includes(this.$route.query.plan)) {
        this.selectedPlan = this.$route.query.plan
      } else {
        this.selectedPlan = 'monthly'
      }
    },

    beforeDestroy() {
      this.currentStep = 1
    }
  }
  ;
</script>

<style>
/* Works on Firefox */
.terms {
  scrollbar-width: thin;
  scrollbar-color: #C130EE #D9DDEC;
}

/* Works on Chrome, Edge, and Safari */
.terms::-webkit-scrollbar {
  width: 5px;
}

.terms::-webkit-scrollbar-track {
  background: #D9DDEC;
  border-radius: 9999px;
}

.terms::-webkit-scrollbar-thumb {
  background-color: #C130EE;
  border-radius: 9999px;
  /*border: 1px solid #D9DDEC;*/
}

.guestio-form-input {
  @apply py-3 !important;
}
</style>
